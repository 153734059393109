<template>
  <v-container pa-5 fluid>
    <formulario-produto :cupom="true" />
  </v-container>
</template>

<script>
import formularioProduto from "./componentes/formularioProduto.vue";
export default {
  components: {
    formularioProduto,
  },

  methods: {},
};
</script>

<style lang="scss">
.border {
  border: 2px dashed gray;
}
</style>
